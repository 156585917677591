import HeaderBar from "components/docs/layout/HeaderBar";
import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { Col, Row } from "react-bootstrap";
import DocumentationSection from "components/docs/DocumentationSection";
import TwoColumnCenteredSection from "components/docs/sections/TwoColumnCenteredSection";
import styled from "styled-components";
import CodeExample from "components/docs/code-examples/CodeExample";
import { ChevronRight, FileJson2 } from "lucide-react";
import { Link } from "gatsby";
import { Alert } from "@merge-api/merge-javascript-shared";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { RouterLocation } from "types/types";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import { LeftCol, RightCol, RowWithoutNegativeMargin } from "./remote-fields/pulling-data";

const TwoColumnSection = styled(TwoColumnCenteredSection)`
  margin-top: 36px;
`;

const DefaultRoundedBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 24px;
  gap: 8px;
  border: 1px solid #eaeef3;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 24px;
`;

interface Props {
  location: RouterLocation;
}

const CustomObjectsPage = ({ location }: Props) => {
  const title = "Custom Objects";
  const description = "Use Merge to interact with custom objects on your users' CRM platforms.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview">
        <>
          <p className="mt-3">
            Read and write data models that are unique to your users&apos; third-party platforms
            directly through Merge&apos;s Unified API, just like Common Models.
          </p>
          <p>
            Each Custom Object has its own unique properties, schema, and associations to other
            objects, which are represented through four models:
          </p>
          <ol>
            <li className="mb-2">Custom Object Class</li>
            <li className="mb-2">Custom Object</li>
            <li className="mb-2">Association Type</li>
            <li className="mb-2">Association</li>
          </ol>
        </>
        <Alert
          className="mt-6 mb-6 playwright-custom-icon"
          color="gray"
          icon={<FileJson2 size={16} />}
        >
          <p className="mb-0">
            See <Link to="/crm/custom-objects/">API Reference</Link> for CRM Custom Objects
            endpoints.
          </p>
        </Alert>
      </DocumentationSection>
      <hr className="mt-6 mb-9" />
      <DocumentationSection title="Custom Object Class">
        <RowWithoutNegativeMargin className="my-9">
          <LeftCol xs={12} md={6}>
            <>
              <p>
                The Custom Object Class contains schema and association types that apply to all
                instances of the class.
              </p>
              <DefaultRoundedBox>
                <p>
                  In this example of Custom Object Class <b>Company Swag</b>, the schema tells us
                  that all Company Swag instances must have a <code>swag_name</code> and a{" "}
                  <code>swag_type</code> field.
                </p>
              </DefaultRoundedBox>
              <Alert color="gray">
                <>
                  <p className="mb-0">
                    Merge supports <strong>GET</strong> requests for this model.&nbsp;
                  </p>
                  <a href="https://docs.merge.dev/crm/custom-object-classes/" target="_blank">
                    <div className="d-flex align-items-center whitespace-nowrap">
                      <div>API Reference </div>
                      <div>
                        <ChevronRight size={14} />
                      </div>
                    </div>
                  </a>
                </>
              </Alert>
            </>
          </LeftCol>

          <RightCol xs={12} md={6}>
            <CodeExample
              folder="custom-objects"
              codeBlockName="CustomObjectClass Example"
              fileBaseName="custom-object-class-example"
              colorScheme="light"
              hasLineNumbers={false}
              isVerticallyPadded={false}
              hideCopyButtonWithHeader
              codeBlockHeight={360}
            />
          </RightCol>
        </RowWithoutNegativeMargin>
      </DocumentationSection>
      <hr className="mt-6 mb-9" />
      <DocumentationSection title="Custom Object">
        <RowWithoutNegativeMargin className="my-9">
          <LeftCol xs={12} md={6}>
            <>
              <p>
                A Custom Object refers to an instance of a Custom Object Class. Custom Objects are
                just like Merge Common Models with their own properties and fields.
              </p>
              <p>
                Each Custom Object has a property <code>custom_object_class_id</code>, which refers
                to the Custom Object Class of this instance.
              </p>
              <DefaultRoundedBox>
                <p>
                  In this example, the Merge T-Shirts Custom Object is an instance of the{" "}
                  <b>Company Swag</b> class with two field values: name <b>Merge T-Shirts</b> and
                  type <b>Clothes</b>.
                </p>
              </DefaultRoundedBox>
              <Alert color="gray">
                <>
                  <p className="mb-0">
                    Merge supports <strong>GET</strong> and <strong>POST</strong> requests for this
                    model.&nbsp;
                  </p>
                  <a href="https://docs.merge.dev/crm/custom-object-classes/" target="_blank">
                    <div className="d-flex align-items-center whitespace-nowrap">
                      <div>API Reference </div>
                      <div>
                        <ChevronRight size={14} />
                      </div>
                    </div>
                  </a>
                </>
              </Alert>
            </>
          </LeftCol>

          <RightCol xs={12} md={6}>
            <CodeExample
              folder="custom-objects"
              codeBlockName="CustomObject Example"
              fileBaseName="custom-object-example"
              colorScheme="light"
              hasLineNumbers={false}
              isVerticallyPadded={false}
              hideCopyButtonWithHeader
              codeBlockHeight={320}
            />
          </RightCol>
        </RowWithoutNegativeMargin>
      </DocumentationSection>
      <hr className="mt-6 mb-9" />
      <DocumentationSection title="Association Type">
        <RowWithoutNegativeMargin className="my-9">
          <LeftCol xs={12} md={6}>
            <>
              <p>
                The Association Type model represents the relationship between two object classes, a
                source and a target. These classes can be Custom Object Classes or Common Models.
              </p>
              <p>
                Each Association Type has a cardinality, which describes the type of relationship
                between the two classes. Possible cardinalities are: <code>ONE_TO_ONE</code>,{" "}
                <code>ONE_TO_MANY</code>, <code>MANY_TO_ONE</code>, and <code>MANY_TO_MANY</code>.
              </p>
              <DefaultRoundedBox>
                <p>
                  For example, we can represent company swag items that are given to people by
                  creating an Association Type from the source <b>Company Swag</b> class to the
                  target <b>Contact</b> Common Model.
                </p>
                <p>
                  The Association Type has cardinality <code>MANY_TO_MANY</code>, because multiple
                  swag items can be given to multiple people.
                </p>
              </DefaultRoundedBox>
              <Alert color="gray">
                <>
                  <p className="mb-0">
                    Merge supports <strong>GET</strong> and <strong>POST</strong> requests for this
                    model.&nbsp;
                  </p>
                  <a href="https://docs.merge.dev/crm/custom-object-classes/" target="_blank">
                    <div className="d-flex align-items-center whitespace-nowrap">
                      <div>API Reference </div>
                      <div>
                        <ChevronRight size={14} />
                      </div>
                    </div>
                  </a>
                </>
              </Alert>
            </>
          </LeftCol>

          <RightCol xs={12} md={6}>
            <CodeExample
              folder="custom-objects"
              codeBlockName="AssociationType Example"
              fileBaseName="association-type-example"
              colorScheme="light"
              hasLineNumbers={false}
              isVerticallyPadded={false}
              hideCopyButtonWithHeader
              codeBlockHeight={360}
            />
          </RightCol>
        </RowWithoutNegativeMargin>
      </DocumentationSection>
      <hr className="mt-6 mb-9" />
      <DocumentationSection title="Association">
        <RowWithoutNegativeMargin className="my-9">
          <LeftCol xs={12} md={6}>
            <>
              <p>
                The Association model describes the relationship between two instances, a source and
                a target. These two instances can be Custom Objects or Common Model objects.
              </p>
              <p>
                Each Association has a property <code>association_type_id</code>, which refers to
                the Association Type of this association.
              </p>
              <DefaultRoundedBox>
                <p>
                  For example, if a Merge T-shirt was given to Hayley, we can represent this
                  relationship with an Association of the Association Type from the previous example
                  with:
                </p>
                <ol>
                  <li className="mb-6">
                    Source pointing to the <b>Merge T-Shirts</b> Custom Object
                  </li>
                  <li>
                    Target pointing to <b>Hayley&apos;s</b> Contact
                  </li>
                </ol>
              </DefaultRoundedBox>
              <Alert color="gray">
                <>
                  <p className="mb-0">
                    Merge supports <strong>GET</strong> and <strong>PATCH</strong> requests for this
                    model.&nbsp;
                  </p>
                  <a href="https://docs.merge.dev/crm/custom-object-classes/" target="_blank">
                    <div className="d-flex align-items-center whitespace-nowrap">
                      <div>API Reference </div>
                      <div>
                        <ChevronRight size={14} />
                      </div>
                    </div>
                  </a>
                </>
              </Alert>
            </>
          </LeftCol>

          <RightCol xs={12} md={6}>
            <CodeExample
              folder="custom-objects"
              codeBlockName="Association Example"
              fileBaseName="association-example"
              colorScheme="light"
              hasLineNumbers={false}
              isVerticallyPadded={false}
              hideCopyButtonWithHeader
              codeBlockHeight={160}
            />
          </RightCol>
        </RowWithoutNegativeMargin>
      </DocumentationSection>
      <CrossGuideLinks location={location} />
    </StaticPageContainer>
  );
};

export default CustomObjectsPage;
