import Icon from "components/generic/Icon";
import { Info } from "lucide-react";
import React, { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { palette, spectrum } from "styles/theme";
import { getAdditionalEndpointInfo } from "../utils/services";

type Props = Pick<React.ComponentProps<"div">, "className" | "children">;

const Card = styled.div`
  background: #f9fafc;
  border: 1px solid #f1f3f4;
  border-radius: 8px;
  padding: 16px 20px;
  font-size: 14px;
  line-height: 24px;
  color: ${spectrum.gray90};
  margin: 8px 0 24px 0;

  p {
    margin-bottom: 8px;
  }

  p:last-child {
    margin-bottom: 0px;
  }
`;

const IconRow = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const HeaderRow = styled(Row)`
  font-size: 16px;
  font-weight: bold;
`;

const BodyRow = styled(Row)``;

const FooterRow = styled(Row)`
  font-size: 12px;
  line-height: 22px;
  color: #737882;
`;

export const NoticeCard = ({ children, className }: Props) => (
  <Card className={className}>{children}</Card>
);

export const BasicNoticeCard = ({
  headerElement,
  bodyElement,
  footerElement,
  feIconName,
  className,
}: Omit<Props, "children"> & {
  headerElement?: ReactElement;
  bodyElement?: ReactElement;
  footerElement?: ReactElement;
  feIconName?: string;
}) => (
  <NoticeCard className={className}>
    <Row className="align-items-center">
      {feIconName && (
        <Col>
          <IconRow>
            <Icon name={feIconName} size="24px" />
          </IconRow>
        </Col>
      )}
      <Col xs={11}>
        {headerElement && <HeaderRow>{headerElement}</HeaderRow>}
        {bodyElement && <BodyRow>{bodyElement}</BodyRow>}
        {footerElement && <FooterRow>{footerElement}</FooterRow>}
      </Col>
    </Row>
  </NoticeCard>
);

const WarningCardDiv = styled(Card)<{ $isVertical: boolean }>`
  display: flex;
  background: ${spectrum.yellow0};
  border: 1px solid ${spectrum.yellow10};
  color: ${palette.black};
  align-items: center;
  ${({ $isVertical }) =>
    $isVertical &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}

  code {
    font-size: inherit;
  }
`;

const WarningIcon = styled.div.attrs({ className: "fe fe-alert-triangle" })`
  color: ${palette.yellow};
  margin-right: 20px;
`;

const WarningHeader = styled.strong`
  font-size: 15px;
`;

const LinkedAccountsInfoIcon = styled(Info)`
  color: ${spectrum.gray90};
`;

export const PricingInfoCard = ({ children, className }: Props) => (
  <Card className={className}>
    <Row className="align-items-center">
      <Col className="col-auto">
        <LinkedAccountsInfoIcon size={18} />
      </Col>
      <Col>{children}</Col>
    </Row>
  </Card>
);

export const WarningCard = ({
  children,
  isVertical,
  className,
}: Props & { isVertical?: boolean }) => (
  <WarningCardDiv $isVertical={isVertical ?? false} className={className}>
    <div className="d-flex">
      <WarningIcon role="img" aria-label="warning" />{" "}
      {isVertical && <WarningHeader className="ml-1 mb-6">Warning</WarningHeader>}
    </div>
    {children}
  </WarningCardDiv>
);

export const InfoCard = styled(Card)`
  background: ${spectrum.indigo0};
  border: 1px solid ${spectrum.indigo10};

  .deprecatedh4 {
    color: ${palette.indigo};
  }

  .deprecatedh4 code {
    line-height: 24px;
    font-size: 15px !important;
  }
  &&& code {
    background: ${palette.white};
    padding: 1px 6px;
    border-radius: 4px;
  }
`;

/**
 * Given a path or tag (eg "available-actions" or "/api/ats/v1/available-actions"), render null or a headerMessage based on the object you're targeting.
 */
export const getCardForCommonModelTitle = (endpointPathOrTag?: string) => {
  if (!endpointPathOrTag) {
    return null;
  }

  const additionalEndpointInfo = getAdditionalEndpointInfo(endpointPathOrTag);

  if (additionalEndpointInfo?.headerWarningMessage) {
    return (
      <WarningCard className="mt-9">{additionalEndpointInfo.headerWarningMessage}</WarningCard>
    );
  }
  return null;
};
