import type { MultiLanguageCodeBlockProps } from "components/docs/shared-components/code-block/MultiOptionCodeBlock";
import { MultiLanguageCodeBlock } from "components/docs/shared-components/code-block/MultiOptionCodeBlock";
import type { SupportedLanguage } from "components/docs/shared-components/code-block/SupportedLanguage";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

export type Props = Omit<MultiLanguageCodeBlockProps, "languageConfigs"> & {
  /**
   * The relative path to the folder containing code we're
   * featuring (from this code-examples folder).
   */
  folder: string;

  /**
   * The name of the file we're looking for, without an
   * extension. This allows loading multiple languages based
   * on extension alone. Defaults to "example" if not specified.
   */
  fileBaseName?: string;

  /**
   * Some languages have the same extensions - this object allows
   * you to pick which of those languages to use as the language
   * label for that file extension.
   */
  languageOverrides?: {
    js: Extract<SupportedLanguage, "javascript" | "node">;
  };

  /**
   * Optional prop that allows you to restrict the height of the code block itself so it can scroll with the title/header in place
   */
  codeBlockHeight?: number;
};

interface CodeExampleQuery {
  allFile: {
    edges: Array<{
      node: {
        name: string;
        extension: string;
        relativeDirectory: string;
        fields?: {
          content: string;
        };
      };
    }>;
  };
}

/**
 * Maps from language to an array of extensions we use for
 * that language when searching for file names. IMPORTANT:
 * the order here determines the order the languages show up
 * inside the `MultiLanguageCodeBlock`. Ordered from most to
 * least common, in blocks of languages (i.e. Python/Ruby/
 * Node/Go/Java/Elixir appear together often, and React/Vue/
 * HTML appear together often).
 *
 * Important: as Node and Javascript have the same extension,
 * we exclude Javascript so the files aren't included
 * twice as two different languages. You can switch which
 * language is used as the label by passing
 */
const LANGUAGE_CONFIGS: Record<Exclude<SupportedLanguage, "javascript">, Array<string>> = {
  json: ["json", "json5"],
  python: ["py"],
  ruby: ["rb"],
  node: ["js"],
  go: ["go"],
  java: ["java"],
  elixir: ["ex"],
  react: ["jsx", "tsx"],
  vue: ["vue"],
  html: ["html"],
  typescript: ["ts"],
  xml: ["xml"],
  bash: ["sh"],
};

/**
 * A component that fetches and displays a code example with
 * optional title, given a file path relative to the
 * `src/components/docs/code-examples` folder.
 */
const CodeExample = ({ folder, fileBaseName = "example", languageOverrides, ...props }: Props) => {
  const data = useStaticQuery<CodeExampleQuery>(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "code-examples" } }) {
        edges {
          node {
            extension
            name
            relativeDirectory
            fields {
              content
            }
          }
        }
      }
    }
  `);

  // Find the files in the specified folder with the right base name and match up extensions for each of our configs
  const languageConfigs = Object.entries(LANGUAGE_CONFIGS).map(([language, extensions]) => {
    const foundNode = data.allFile.edges.find(
      ({ node }) =>
        extensions.includes(node.extension) &&
        node.name === fileBaseName &&
        node.relativeDirectory === folder,
    )?.node;

    // See if we should override the language
    let foundLanguage = language as SupportedLanguage;
    if (foundNode?.extension && languageOverrides && foundNode.extension in languageOverrides) {
      const override = languageOverrides[foundNode.extension as keyof typeof languageOverrides];
      foundLanguage = override ?? foundLanguage;
    }
    return { language: foundLanguage, text: foundNode?.fields?.content };
  });

  return <MultiLanguageCodeBlock {...props} languageConfigs={languageConfigs} />;
};

export default CodeExample;
