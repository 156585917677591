import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { Col, Row } from "react-bootstrap";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { PageProps } from "gatsby";
import DocumentationSection from "components/docs/DocumentationSection";
import CodeExample from "components/docs/code-examples/CodeExample";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import styled from "styled-components";
import { MERGE_PRICING_PAGE } from "components/docs/navigation/links";
import { PricingInfoCard } from "components/docs/shared-components/NoticeCards";
import TwoColumnCenteredSection from "components/docs/sections/TwoColumnCenteredSection";
import { ColumnDiv } from "../field-mappings/create-field-mapping/for-linked-account";
export const FittedCodeExample = styled(CodeExample)`
  width: 640px;
  margin: auto;
  padding-top: 22px;
`;

const EndpointMethodContainer = styled.div`
  width: 60%;
`;

export const RowWithoutNegativeMargin = styled(Row)`
  && {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const LeftCol = styled(Col)`
  && {
    @media (min-width: 768px) {
      padding-right: 16px;
    }
    padding-left: 0;
    padding-right: 0;
  }
`;

export const RightCol = styled(Col)`
  && {
    @media (min-width: 768px) {
      padding-left: 16px;
    }
    padding-left: 0;
    padding-right: 0;
  }
`;

/**
 * Creates a Target Fields page for Field Mapping guide
 */
const RemoteFieldsPullingData = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Pulling Remote Field Classes";
  const description =
    "Pull metadata and values for all fields from Remote Source Models in a standardized format, including standard and custom fields. ";

  return (
    <StaticPageContainer>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} isBeta={true} className="pb-9">
        <PricingInfoCard className="mb-9 mt-0">
          This feature is only available to customers on our{" "}
          <b className="weight-semibold">Professional</b> and{" "}
          <b className="weight-semibold">Enterprise</b> plans. View the{" "}
          <a href={MERGE_PRICING_PAGE} target="_blank" rel="noreferrer">
            {" "}
            Merge Plans
          </a>{" "}
          to learn more.
        </PricingInfoCard>
      </HeaderBar>
      <DocumentationSection title="Endpoints and Query Parameters">
        <div className="mt-6">
          <div className="d-flex flex-row w-100">
            <EndpointMethodContainer className="d-flex mr-4 w-3/5">
              <div className="mr-2 text-blue weight-semibold">GET</div>
              <div>
                {"/{common_model_name}/"}
                <strong className="weight-semibold">remote-field-classes</strong>
              </div>
            </EndpointMethodContainer>
            <div className="d-flex w-40">Read Remote Field Classes for a Common Model</div>
          </div>
          <hr />
          <div className="d-flex flex-row w-100">
            <EndpointMethodContainer className="d-flex mr-4">
              <div className="mr-2 text-blue weight-semibold">GET</div>
              <div>{"/{common_model_name}"}</div>
              <strong className="weight-semibold">?include_remote_fields=True</strong>
            </EndpointMethodContainer>
            <div className="d-flex w-40">
              Read Remote Field Classes and values for a Common Model
            </div>
          </div>
          <hr />
          <div className="d-flex flex-row w-100">
            <EndpointMethodContainer className="d-flex mr-4">
              <div className="mr-2 text-blue weight-semibold">GET</div>
              <div>
                {"/{common_model_name}/{id}"}
                <strong className="weight-semibold">?include_remote_fields=True</strong>
              </div>
            </EndpointMethodContainer>
            <div className="d-flex w-40">
              Read Remote Field Classes and values for a Common Model instance
            </div>
          </div>
        </div>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Example">
        <>
          <p className="mb-6">
            For this example, let’s use custom fields set up on the{" "}
            <strong className="weight-semibold">Contacts</strong> model in Hubspot, which is mapped
            to the Merge <strong className="weight-semibold">Contact</strong> Common Model.
          </p>
          <p>We can access Remote Field Classes for Contacts using this endpoint:</p>
          <div className="d-flex flex-row mb-4">
            <div className="mr-2 text-blue weight-semibold">GET</div>
            <div>
              <strong className="weight-semibold">
                https://api.merge.dev/api/crm/v1/contacts/remote-field-classes
              </strong>
            </div>
          </div>
        </>
        <RowWithoutNegativeMargin>
          <LeftCol xs={12} md={6}>
            <>
              <p className="mt-5">
                These two Remote Field Classes in the response are both{" "}
                <strong className="weight-bold">custom</strong> fields defined by a Hubspot user, as
                we can tell from the <code>is_custom</code> flag being set to <code>true</code>.
              </p>
              <p>
                The <code>test_dropdown_custom_field</code> also has a list of accepted values under{" "}
                <code>field_choices</code>.
              </p>
            </>
          </LeftCol>

          <RightCol xs={12} md={6}>
            <ColumnDiv>
              <FittedCodeExample
                folder="remote-fields"
                codeBlockName="Example Response - GET /contacts/remote-field-classes"
                fileBaseName="remote-field-classes-example-1"
                colorScheme="light"
                hasLineNumbers={false}
                isVerticallyPadded={false}
                hideCopyButtonWithHeader
                className="w-100"
              />
            </ColumnDiv>
          </RightCol>
        </RowWithoutNegativeMargin>

        <RowWithoutNegativeMargin className="mt-9">
          <LeftCol xs={12} md={6}>
            <>
              <p className="">
                Meanwhile, these two Remote Field Classes are{" "}
                <strong className="weight-bold">standard</strong> fields from Hubspot, as the{" "}
                <code>is_custom</code> flag is set to <code>false</code>.
              </p>
            </>
          </LeftCol>

          <RightCol xs={12} md={6}>
            <ColumnDiv>
              <FittedCodeExample
                folder="remote-fields"
                codeBlockName="Example Response - GET /contacts/remote-field-classes"
                fileBaseName="remote-field-classes-example-2"
                colorScheme="light"
                hasLineNumbers={false}
                isVerticallyPadded={false}
                hideCopyButtonWithHeader
                className="w-100 pt-0"
              />
            </ColumnDiv>
          </RightCol>
        </RowWithoutNegativeMargin>
        <RowWithoutNegativeMargin className="my-9">
          <LeftCol xs={12} md={6}>
            <>
              <p className="">
                To access Remote Field Classes and values for a specific Contact, use the{" "}
                <strong className="weight-semibold">include_remote_fields=True</strong> query
                parameter.
              </p>
              <p>
                This returns all of an object’s properties including Remote Field Classes metadata
                and values.
              </p>
            </>
          </LeftCol>

          <RightCol xs={12} md={6}>
            <FittedCodeExample
              folder="remote-fields"
              codeBlockName="Example Response - GET /contacts/{id}?include_remote_fields=True"
              fileBaseName="remote-field-classes-example-3"
              colorScheme="light"
              hasLineNumbers={false}
              isVerticallyPadded={false}
              hideCopyButtonWithHeader
              className="w-100 pt-0"
            />
          </RightCol>
        </RowWithoutNegativeMargin>
      </DocumentationSection>
      <CrossGuideLinks location={location} />
    </StaticPageContainer>
  );
};

export default RemoteFieldsPullingData;
